import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Header } from '../components/header';
import { Footer } from '../components/footer';

import * as styles from './index.module.css';
import * as stylesSection from '../components/section.module.css';

const MentionsLegales = ({ data }) => {
  return (
    <div className={styles.main}>
      <div>
        <Helmet>
          <meta name="description" content="Mentions Légales aude-bien-etre" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>

      <div className={styles.mainContent}>
        <h1>Mentions Légales</h1>
        <div className={styles.text}>
          <h2>Propriété du site</h2>
          <p>
            Le présent site appartient à Aude-bien-être <br />2 rue de Port Maria
            <br /> 44300 NANTES
          </p>
          <h2>Photos</h2>
          <p>
            Crédit pour la photo de Aude Fasani:
            <br />
            Céline Piat{' '}
            <a className={stylesSection.link} href="https://livingcolors-studio.com/">
              living colors studio
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default MentionsLegales;
